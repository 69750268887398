<template>
  <v-card class="overflow-hidden">
    <v-app-bar dense elevation="0" absolute fixed>
      <v-toolbar-title>Tools</v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-slot:extension>
        <v-tabs v-model="adminTab">
          <v-tabs-slider color="pink"></v-tabs-slider>
          <v-tab href='#data'>Data</v-tab>
          <v-tab href='#setting'>Settings</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main class="mt-9">
    <v-layout>
      <v-tabs-items v-model="adminTab">
        <v-tab-item value="data">
          <v-list three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Import albums</v-list-item-title>
                <v-list-item-subtitle>Imports albums from Shopify</v-list-item-subtitle>
                <v-checkbox v-model="clearAlbums" label="Clear albums"/>
                <v-btn color="secondary" @click="importAlbums">Import Albums</v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Import customers</v-list-item-title>
                <v-list-item-subtitle>Imports customers from Shopify</v-list-item-subtitle>
                <v-checkbox v-model="clearCustomers" label="Clear customers"/>
                <v-btn color="secondary" @click="importCustomers">Import Customers</v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Import orders</v-list-item-title>
                <v-list-item-subtitle>Imports orders from Shopify</v-list-item-subtitle>
                <v-checkbox v-model="clearOrders" label="Clear orders"/>
                <v-btn color="secondary" @click="importOrders">Import Orders</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

        </v-tab-item>
        <v-tab-item value="setting">
          <v-divider></v-divider>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
    </v-main>
    <v-snackbar v-model="snackbar" timeout="8000" :absolute=true>
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn depressed small v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import toolService from '@/services/toolService'

export default {
  name: "Admin",
  data() {
    return {
      snackbar: false,
      snackbarMsg: '',
      adminTab: "data",
      clearAlbums: false,
      clearCustomers: false,
      clearOrders: false,
    }
  },
  methods: {
    importAlbums() {
      return toolService.importAlbums(this.clearAlbums).then (response => {
        this.snackbarMsg = response.data.msg
        this.snackbar = true
      }).catch( err => {
        this.snackbarMsg = err
        this.snackbar = true
      })
    },
    importCustomers() {
      return toolService.importCustomers(this.clearCustomers).then (response => {
        this.snackbarMsg = response.data.msg
        this.snackbar = true
      }).catch( err => {
        this.snackbarMsg = err
        this.snackbar = true
      })
    },
    importOrders() {
      return toolService.importOrders(this.clearOrders).then (response => {
        this.snackbarMsg = response.data.msg
        this.snackbar = true
      }).catch( err => {
        this.snackbarMsg = err
        this.snackbar = true
      })
    }
  }
}
</script>

<style scoped>

</style>