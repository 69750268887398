import apiClient from "@/services/apiClient";

export default {
  importAlbums(clearFlag) {
    let queryStr = '?clear='+clearFlag
    return apiClient.post('/admin/albums/import' + queryStr);
  },
  importCustomers(clearFlag) {
    let queryStr = '?clear='+clearFlag
    return apiClient.post('/admin/customers/import' + queryStr);
  },
  importOrders(clearFlag) {
    let queryStr = '?clear='+clearFlag
    return apiClient.post('/admin/orders/import' + queryStr);
  }
}